.App {
  text-align: center;
}

.disabledDiv {
  pointer-events: none;
  opacity: 0.4;
}

.witoutOpacity {
  pointer-events: none;
}

/* Game.css or your CSS file */
.square-button {
  width: min(6vw, 6vh);
  height: min(6vw, 6vh);
  margin: 6px;
  font-size: min(2vw, 2vh);
  border: none;
  cursor: pointer;
}

/* TicTacToe.css */
.boardContainer {
  position: relative;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 75%;
  height: 75%;
  z-index: 1;
  font-size: min(22vw, 22vh);
  text-shadow: rgb(255, 0, 0) 10px 0px 3px
}

.board {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
  margin-right: 20px;
}

.row {
  display: flex;
}

.square-button.disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

/* .App-logo {
  height: 40vmin;
  pointer-events: none;
} */

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/* GamePanel.tsx*/

.modeSwitchLabel {
  position: relative;
  display: inline-block;
  width: min(12vw, 12vh);
  height: min(6vw, 6vh);
}

.modeSwitchLabel input {
  opacity: 0;
  width: 0;
  height: 0;
}

.modeSwitchSpanSlider {
  position: absolute;
  cursor: pointer;

  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  background-color: rgb(255, 209, 220);
  transition: 0.4s;
}

.modeSwitchSpanSlider:before {
  position: absolute;
  content: "";
  height: min(4vw, 4vh);
  width: min(4vw, 4vh);
  left: min(1vw, 1vh);
  bottom: min(1vw, 1vh);
  background-color: white;
  transition: 0.4s;
  border-radius: min(min(4vw, 4vh), min(4vw, 4vh));
}

.modeSwitchInput:checked + .modeSwitchSpanSlider {
  background-color: rgb(135, 206, 235);
}

.modeSwitchInput:checked + .modeSwitchSpanSlider:before {
  transform: translateX(min(6vw, 6vh));
}

.modeSwitchSpanSlider.rounded {
  border-radius: min(min(4vw, 4vh), min(4vw, 4vh));
}